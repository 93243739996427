import Link from "next/link";
import { Dispatch, SetStateAction } from "react";

interface MenuOverlayProps {
  navbarOpen: boolean;
  setNavbarOpen: Dispatch<SetStateAction<boolean>>;
}

const navigation = [
  { name: "Mood", href: "/moods", current: false },
  { name: "Series", href: "/series", current: false },
  { name: "Narrative", href: "/narratives", current: false },
  { name: "Journey", href: "/journey", current: false },
];

const MenuOverlay = ({ navbarOpen, setNavbarOpen }: MenuOverlayProps) => {
  return (
    <nav
      className={`font-bebas z-100 bg-black text-white text-center fixed flex top-0 left-0 w-full px-10 z-10 h-screen  bg-tertiary  uppercase bg-opacity-100 transform delay-100 transition-all duration-300 ${
        navbarOpen ? "opacity-100 translate-x-0" : "opacity-0 -translate-x-full"
      }`}
    >
      <ul className="w-full h-screen flex flex-col justify-center items-center">
        {navigation.map((item) => (
          <li className="nav-li my-6 text-4xl tracking-widest font-roboto" key={item.name}>
            <Link
              href={item.href}
              className="nav-link"
              onClick={(e) => {
                setNavbarOpen(false);
              }}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default MenuOverlay;
