"use client";

import { useState } from "react";
import Header from "../Header";
import MenuOverlay from "../MenuOverlay";

export const Hamburger = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <>
      <Header navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
      <MenuOverlay navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
    </>
  );
};
export default Hamburger;
