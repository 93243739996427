"use client";
import { useAuth } from "@/context/AuthUserProvider";
import { Disclosure } from "@headlessui/react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import router from "next/router";
import { useEffect } from "react";
const navigation = [
  { name: "ENERGY", href: "/moods", current: false },
  { name: "PORTAL", href: "/series", current: false },
  { name: "Narrative", href: "/narratives", current: false },
  { name: "Journey", href: "/journey", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const pathname = usePathname();
  const { authUser, loading, signOut } = useAuth();

  useEffect(() => {
    if (!loading && !authUser) router.push("/login");
  }, [authUser, loading]);
  return (
    <Disclosure as="nav" className=" bg-black uppercase">
      {({ open }) => (
        <>
          <div className="absolute w-screen mx-auto px-2 sm:px-6 lg:px-8 border-b border-white z-10">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start ">
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    <Link href="/">
                      <Image src="/logo-line.png" alt="logo" width={80} height={40} />
                    </Link>
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.href === pathname ? "bold text-white" : "text-gray-300 hover:bold hover:text-white",
                          "rounded-md px-3 py-2 text-sm font-medium tracking-[3px]",
                          pathname === "/" ? "hidden" : ""
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <Link href="/about">
                  <button
                    className={classNames(
                      "px-3 py-2 text-sm font-medium tracking-[3px] text-gray-300 hover:bold hover:text-white uppercase"
                    )}
                  >
                    about
                  </button>
                </Link>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <button
                  onClick={signOut}
                  className={classNames(
                    "px-3 py-2 text-sm font-medium tracking-[3px] text-gray-300 hover:bold hover:text-white uppercase"
                  )}
                >
                  signout
                </button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
