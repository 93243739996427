"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { Dispatch, SetStateAction } from "react";

interface HeaderProps {
  navbarOpen: boolean;
  setNavbarOpen: Dispatch<SetStateAction<boolean>>;
}

const Header = ({ navbarOpen, setNavbarOpen }: HeaderProps) => {
  const pathname = usePathname();
  return (
    pathname !== "/access-denied" && (
      <header
        className={`font-bebas ${
          pathname?.includes("/video/") ? "bg-black md:bg-transparent" : "bg-transparent"
        }  w-full ${pathname === "/" ? " fixed " : ""} md:fixed top-0 left-0 p-4 md:p-10 md:pt-7 flex z-20`}
      >
        <div className="flex-grow z-20">
          {pathname != "/" && (
            <Link
              href="/"
              className="font-bebas text-xl nav-link"
              onClick={(e) => {
                setNavbarOpen(false);
              }}
            ></Link>
          )}
        </div>{" "}
        {pathname?.includes("/video/") && (
          <Link href="/payment">
            <button className="bg-primary rounded-full text-white px-5 py-1 pt-2">CONTRIBUTE</button>
          </Link>
        )}
        <button
          className="font-lg:hidden flex top-0 right-0 z-20 relative w-10 h-7 text-white focus:outline-none"
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          <div className="absolute w-5 transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
            <span
              className={`absolute h-0.5 w-5 ${
                pathname === "/event" || pathname === "/screenings"
                  ? "bg-black "
                  : pathname === "/" || pathname?.includes("/video/")
                  ? "bg-white"
                  : "bg-black"
              } transform transition duration-300 ease-in-out ${
                navbarOpen ? "rotate-45 delay-200" : "-translate-y-1.5"
              }`}
            ></span>
            <span
              className={`absolute h-0.5 ${
                pathname === "/event" || pathname === "/screenings"
                  ? "bg-black"
                  : pathname === "/" || pathname?.includes("/video/")
                  ? "bg-white"
                  : "bg-black"
              } transform transition-all duration-200 ease-in-out ${
                navbarOpen ? "w-0 opacity-50" : "w-5 delay-200 opacity-100"
              }`}
            ></span>
            <span
              className={`absolute h-0.5 w-5 ${
                pathname === "/event" || pathname === "/screenings"
                  ? "bg-black"
                  : pathname === "/" || pathname?.includes("/video/")
                  ? "bg-white"
                  : "bg-black"
              } transform transition duration-300 ease-in-out ${
                navbarOpen ? "-rotate-45 delay-200" : "translate-y-1.5"
              }`}
            ></span>
          </div>
        </button>
      </header>
    )
  );
};
export default Header;
