"use client";
import Hamburger from "@/components/Hamburger";
import Navbar from "@/components/Navbar";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import { ReactNode, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useAuth } from "../context/AuthUserProvider";

interface LayoutProps {
  children: ReactNode;
}

function Layout({ children }: LayoutProps) {
  const router = useRouter();
  const { authUser, loading } = useAuth();
  useEffect(() => {
    if (!loading && !authUser) router.push("/login");
  }, [authUser, loading]);

  // useEffect(() => {
  //   const handleRouteChange = () => {
  //     Transport.stop();
  //     Transport.cancel();
  //     Transport.position = 0;
  //     console.log("Back or forward button used");
  //     // You can do something here, such as track analytics or update state
  //   };

  //   router.events.on("routeChangeStart", handleRouteChange);

  //   return () => {
  //     router.events.off("routeChangeStart", handleRouteChange);
  //   };
  // }, [router.events]);

  const pathname = usePathname();

  return (
    authUser && (
      <>
        <div className="block sm:hidden">
          <Hamburger />
        </div>
        <div className="hidden sm:block">
          {" "}
          <Navbar />{" "}
        </div>
        <ToastContainer autoClose={2000} />
        <div className="bg-primary min-h-screen h-full text-[#ccc] w-screen"> {children}</div>;
      </>
    )
  );
}

export default Layout;
